import { contract_address } from "config";
import { contract_abi } from "config";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import Web3 from "web3";
import {
  getInfoStaking,
  staking,
  getInfoUser,
  addTree,
  checkApproveStaking,
  submitApproveStaking,
  unStaking,
  withdraw
} from "service";

const LoadingButton = ({ className }) => {
  return (
    <button className={className}>
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </button>
  );
};

const Staking = (props) => {
  const state = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [profit, setProfit] = useState(0);
  const [approve, setApprove] = useState(false);
  const [packageStaking, setPackageStaking] = useState({});
  const [BNB, setBNB] = useState(0);
  const [SFT, setSFT] = useState(0);
  const [balance, setBalance] = useState(0);
  const [price, setPrice] = useState({
    symbolPrice: 0,
    priceSFT: 0,
    priceBNB: 0,
    bnb: 0,
  });

  useEffect(() => {
    setInterval(() => {
      getData();
    }, 1000);
  }, [1000]);

  useEffect(() => {
    // getProfit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.user]);

  const getData = async () => {
    const infoStaking = await getInfoStaking();
    const checkApp = await checkApproveStaking();
    setApprove(checkApp.stt);
    setBalance(checkApp.balance);
    let obj = {};


    obj.amount = Number(infoStaking.amount) / 1e18;
    obj.received = Number(infoStaking.received) / 1e18;
    obj.timestamp = Number(infoStaking.timestamp);
    obj.pt = Number(infoStaking.pt);
    let times = Math.floor(Date.now() / 1000) - obj.timestamp;
    obj.profit = (obj.amount * times * obj.pt / 259200000) - obj.received;
    if (obj.amount == 0) {
      obj.pt = 0
    }
    setPackageStaking(obj)
  };


  // ô điền số lượng
  // nút đầu tư
  // tổng đầu tư
  // % / tháng
  // số dư
  // tổng lợi nhuận
  // đã rút
  // rút
  const onConfirmSFT = async () => {
    // console.log('SFT is ', SFT);
    // if (SFT < 100) {
    //   NotificationManager.error("Min 100", "Error", 10000);
    // }
    setLoading(true);
    let data = await staking(SFT);
    console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Investment Success", "Success", 10000);
    }
    setLoading(false);
    getData();
  }

  const _approve = async () => {
    setLoading(true);
    let data = await submitApproveStaking();
    console.log(data);
    if (!data) {

    }

    setLoading(false);
    getData();
  }

  const onClaim = async () => {
    setLoading(true);
    let data = await withdraw();
    console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Withdraw Success", "Success", 10000);
    }

    setLoading(false);
    getData();
  }

  const _unStaking = async () => {
    window.location.replace('/bat/info')
    // setLoading(true);
    // let data = await unStaking();
    // console.log(data);
    // if (!data) {
    //   NotificationManager.error("Error", "Error", 10000);
    // } else {
    //   NotificationManager.success("Success", "Error", 10000);
    // }

    // setLoading(false);
    // getData();
  }


  return (

    <div className="site-main-content__buy-sft">
      {/* <p>
        Batstaking temporarily updated data until the end of September 20th and everything is back to normal.
      </p> */}
      <h6 className="main-title" style={{ textAlign: 'center', color: 'red' }}>
      Batstaking.com respectfully announces! Dear customers and investors of the Batstaking.com project. Due to the new legal regulations in the host country allowing the project to continue operating, we need more time to supplement and complete the legal documents for the upgraded technology software of the Batstaking.com project. The expected completion date of the legal documents will be April 29, 2025. If after the expected date of completion of the legal documents, Batstaking.com does not reopen for customers and investors to withdraw BAT, we commit to return all BAT that customers and investors have invested in Batstaking.com. We once again sincerely apologize to customers and investors of Batstaking.com for this unexpected inconvenience. Sincerely thank you!
      </h6>
      <div className="container">
        <div className="manage-your-token-title">
          <h3 className="main-title">Staking</h3>
        </div>
        <div className="row">

          <div className="col col-12 col-sm-6">
            <div className="block-item item-swap-rates">
              <div className="block-item__wrap">
                <div className="block-item__content">
                  <div className="buy-sft">
                    <div className="row">
                      <p>Balance BAT: {balance.toFixed(2)}</p>
                      <div className="buy-sft-field col col-12">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="0"
                          onChange={(e) => {
                            setSFT(Number(e.target.value));
                          }}
                        />
                        <span className="unit">BAT</span>
                      </div>
                    </div>
                    {
                      approve ? <div className="buy-sft-action text-center">
                        {loading ? (
                          <LoadingButton className="btn btn-primary" />
                        ) : (
                          <button
                            className="btn btn-primary"
                            onClick={onConfirmSFT}
                          >
                            Investment
                          </button>
                        )}
                      </div>
                        :
                        < div className="buy-sft-action text-center">
                          {loading ? (
                            <LoadingButton className="btn btn-primary" />
                          ) : (
                            <button
                              className="btn btn-primary"
                              onClick={_approve}
                            >
                              Approve
                            </button>
                          )}
                        </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-12 col-sm-6">
            <div className="block-item item-swap-rates">
              <div className="block-item__wrap">
                <div className="block-item__content">
                  <div className="buy-sft">
                    <div className="row">
                      <div className="mb-4">Note: </div>
                      <p>* All profits are accumulated in the Info section.</p>
                      {/* <p>* Please long Claim before using this function!</p>
                      <p>* Please long Claim before using this function!</p> */}
                    </div>
                    <div className="buy-sft-action text-center">
                      {loading ? (
                        <LoadingButton className="btn btn-primary" />
                      ) : (
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            _unStaking();
                          }}
                        >
                          Go To INFO
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="manage-your-token-title">
          <p className="sub-title">PERSONAL TITLE</p>
          <h3 className="main-title">Check and manage your BAT</h3>
        </div> */}
        <div className="row">
          <div className="col col-12 col-md-3">
            <div className="block-item item-manage-your-token">
              <div className="block-item__wrap text-center">
                <h3 className="block-item__title">% you can claim</h3>
                <div className="block-item__content">
                  <h3>
                    <span className="amount">
                      {
                        Number(packageStaking?.pt || 0).toFixed(0)}
                    </span>
                    %
                  </h3>
                </div>
                <div className="block-item__footer">Per month</div>
              </div>
            </div>
          </div>
          <div className="col col-12 col-md-3">
            <div className="block-item item-manage-your-token">
              <div className="block-item__wrap text-center">
                <h3 className="block-item__title">BAT bought</h3>
                <div className="block-item__content">
                  <h3>
                    <span className="amount">
                      {Number(packageStaking?.amount || 0).toFixed(2)}
                    </span>
                  </h3>
                </div>
                <div className="block-item__footer">BAT</div>
              </div>
            </div>
          </div>
          <div className="col col-12 col-md-3">
            <div className="block-item item-manage-your-token">
              <div className="block-item__wrap  text-center">
                <h3 className="block-item__title">BAT claimed</h3>
                <div className="block-item__content">
                  <h3>
                    <span className="amount">
                      {Number(packageStaking?.received || 0).toFixed(
                        6
                      )}
                    </span>
                  </h3>
                </div>
                <div className="block-item__footer">BAT</div>
              </div>
            </div>
          </div>
          <div className="col col-12 col-md-3">
            <div className="block-item item-manage-your-token">
              <div className="block-item__wrap text-center">
                <h3 className="block-item__title">BAT ready for claim</h3>
                <div className="block-item__content">
                  <h3>
                    <span className="amount">
                      {(packageStaking.profit || 0).toFixed(
                        Number(profit) > 100
                          ? 2
                          : Number(profit) > 10
                            ? 4
                            : Number(profit) > 1
                              ? 6
                              : 8
                      )}
                    </span>
                  </h3>
                </div>
                <div className="block-item__footer">
                  {loading ? (
                    <LoadingButton className="btn btn-primary" />
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary btn-claim"
                      onClick={onClaim}
                    >
                      CLAIM
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Staking;
